export default [
  {
    title: "Britannica Academic",
    description:
      "Discover encyclopedia articles, full-text journal and magazine articles, primary sources, multimedia, and other unique resources and tools that make research easier and more productive.",
    link: "https://academic.eb.com",
    image: "britannica-academic.png",
    code: false,
    ip: true,
  },
  {
    title: "Britannica ImageQuest",
    description:
      "Britannica ImageQuest has over three million images from 60 leading collections…! all rights-cleared for educational, non-commercial use",
    link: "https://quest.eb.com",
    image: "britannica-imagequest.png",
    code: false,
    ip: true,
  },
  {
    title: "FWU",
    description:
      "Circa 4‘400 didaktische Filme und Sequenzen, zahlreiche Bildgalerien, Arbeitsblätter und interaktive Lerneinheiten per Mausklick abrufen.<br>Bitte den Zugangscode in der Mediothek verlangen.",
    link: "http://www.fwu-mediathek.ch",
    image: "fwu.png",
    code: true,
    ip: false,
  },
  {
    title: "dokumentARfilm ",
    description:
      "Hier finden Sie alle Medien der Anne Roerkohl dokumentARfilm GmbH für die Fächer Geschichte und Deutsch.",
    link: "https://mediathek.dokumentarfilm.com/mediathek",
    image: "dokumentARfilm.jpg",
    code: true,
    ip: false,
  },
  {
    title: "Schweizer Zeitungsarchiv",
    description: "Schweizer Zeitungsarchiv",
    link: "https://essentials.swissdox.ch",
    image: "swissdox.gif",
    code: false,
    ip: true,
  },
  {
    title: "Kindlers Litartur Lexikon",
    description: "13'000 Werke von Belletristik bis Sachliteratur.",
    link: "https://online.munzinger.de/publikation/klL",
    image: "kindler.gif",
    code: false,
    ip: true,
  },
  {
    title: "Brockhaus",
    description:
      "Brockhaus Enzyklopädie Online bildet das gesamte Wissen ab. Die Texte sind von Fachpersonen verfasst, mit Inhaltsverzeichnis geordnet und mit weiterführenden Links sowie Bildern, Grafiken, Video- und Audio-Beiträgen ergänzt.",
    link: "http://digithek.brockhaus.de",
    image: "brockhaus.png",
    code: false,
    ip: true,
  },
  {
    title: "Munzinger Chronik",
    description:
      "Die MUNZINGER Chronik bringt seit 1986 die Ereignisse der Welt in mehr als 67.000 Meldungen kurz und prägnant auf den Punkt.",
    link: "https://online.munzinger.de/publikation/chronik",
    image: "munzinger-chronik.png",
    code: false,
    ip: true,
  },
  {
    title: "Munzinger Länder",
    description:
      "MUNZINGER Länder bietet einen umfassenden Blick auf die wichtigsten Daten und Fakten aller Staaten und internationaler Zusammenschlüsse sowie Organisationen – übersichtlich dargestellt und stets aktuell.",
    link: "https://online.munzinger.de/publikation/laender",
    image: "munzinger-laender.png",
    code: false,
    ip: true,
  },
  {
    title: "Munzinger Personen",
    description:
      "Die Datenbank MUNZINGER Personen dient Journalistinnen und Journalisten sowie Entscheidungsträgern in Politik und Wirtschaft als wertvolle und prägnante Informationsquelle und gehört heute zur Grundausstattung öffentlicher und wissenschaftlicher Bibliotheken.",
    link: "https://online.munzinger.de/publikation/personen",
    image: "munzinger-personen.png",
    code: false,
    ip: true,
  },

  {
    title: "Wissenschaft Online",
    description:
      "Naturwissenschaftliches Portal zu Artikeln aus „Spektrum der Wissenschaft“ und Lexika aus den Bereichen: Biologie, Chemie, Ernährung, Geografie, Physik, Psychologie u.a.",
    link: "http://www.wissenschaft-online.de",
    image: "wissenschaft-online.gif",
    code: false,
    ip: true,
  },
  {
    title: "Spektrum der Wissenschaft",
    description: "Die Hefte als PDF zum Download.",
    link: "https://www.spektrum.de/shop/spektrum-der-wissenschaft/archiv/",
    image: "spektrum.png",
    code: false,
    ip: true,
  },
  {
    title: "Die Woche",
    description: "Das wöchentliche Magazin als PDF zum Download.",
    link: "http://www.spektrum.de/alias/859070/d_sdwv_heftarchiv_html",
    image: "spektrum_woche.png",
    code: false,
    ip: true,
  },
  {
    title: "Geist und Gehirn",
    description:
      "Das Magazin für Psychologie und Gehirnforschung. Die Hefte als PDF zum Download.",
    link: "http://www.spektrum.de/alias/859070/d_sdwv_heftarchiv_html",
    image: "geistundgehirn.png",
    code: false,
    ip: true,
  },
  {
    title: "deutschsprachige Gegenwartsliteratur",
    description:
      "Standardwerk zur deutschsprachigen Literatur nach 1945 mit Informationen zu Biografie und Werk sowie Angaben zu Sekundärliteratur und Rezensionen.",
    link: "https://online.munzinger.de/publikation/klg",
    image: "klg.jpg",
    code: false,
    ip: true,
  },
  {
    title: "fremdsprachige Gegenwartsliteratur",
    description:
      "Leben und Werk herausragender zeitgenössischer Schriftsteller*innen der Weltliteratur, deren Werk zum grossen Teil in deutscher Übersetzung vorliegt.",
    link: "https://online.munzinger.de/publikation/klfg",
    image: "klfg.jpg",
    code: false,
    ip: true,
  },
  {
    title: "Komponisten der Gegenwart",
    description:
      "Nachschlagewerk zu den wichtigsten Komponisten/Komponistinnen des 20. und 21. Jahrhunderts. Biografien, Werküberblicke und detaillierten Angaben zu den einzelnen Werken, teilweise mit Notentafeln und Erläuterungen zur Kompositionstechnik.",
    link: "https://online.munzinger.de/publikation/kdg",
    image: "kdg.jpg",
    code: false,
    ip: true,
  },
  {
    title: "Statistisches Lexikon",
    description:
      "Das Statistische Lexikon der Schweiz enthält viele Tabellen (mit historischen Zeitreihen), Grafiken, zahlreiche Karten und Texte (Gesamtübersichten und Analysen des statistischen Jahrbuchs der Schweiz).",
    link: "https://www.bfs.admin.ch/bfs/de/home/statistiken/kataloge-datenbanken.htm",
    image: "bfs.png",
    code: false,
    ip: false,
  },
  {
    title: "Keystone",
    description:
      "Bilddatenbank. Themen wie Mensch und Gesellschaft unter Creative; aktuelle und historische Ereignisse unter Editorial. Verwendung mit Quellenangabe in nicht kommerziellen Schul-Arbeiten - via Download.",
    link: "https://visual.keystone-sda.ch/customlogin/-/login/authenticate/l4bAmfSv/2u4DgThk0",
    image: "keystone.jpg",
    code: false,
    ip: true,
  },
  {
    title: "Digithek",
    description: "weitere Links zu Nachschlagewerken im Internet.",
    link: "http://www.digithek.ch",
    image: "digithek.gif",
    code: false,
    ip: true,
  },
];
